import { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';

import EmptyProfile from '../../../images/dashboard/empty-profile.png'
import { AiFillStar } from "react-icons/ai";

import EditAstrologerProfile from './EditAstrologerProfile';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetSingleAstrologer, UpdatePauseOrUnPauseAstrologer, successMessage, errorMessage } from '../../../Redux/Slice';

function AstrologerProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { AstrologerProfile, success, errors } = useSelector((state) => state.Astromind);
    const { id } = useParams();
    const token = localStorage.getItem('admin-auth-token');

    const [astrologerEdit, setAstrologerEdit] = useState(false);

    const closeEdit = (value) => {
        setAstrologerEdit(value);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(GetSingleAstrologer({ token, id }));
    }, [dispatch, token, id]);

    let description = AstrologerProfile?.data?.data?.astrologer?.description?.data.map(obj => obj.name);
    let language = AstrologerProfile?.data?.data?.astrologer?.language?.data.map(obj => obj.name);

    let profile;
    let idProof;
    let passbook;
    if (AstrologerProfile?.data?.data?.files?.length > 0) {
        AstrologerProfile?.data?.data?.files.map((file) => {
            if (file.filefor === "profile") {
                profile = file;
            }
            if (file.filefor === "idproof") {
                idProof = file;
            }
            if (file.filefor === "passbook") {
                passbook = file;
            }
        })
    }
    else {
        profile = null;
        idProof = null;
        passbook = null;
    }

    const handlePauseUnpauseAstrologer = (id) => {
        let status = AstrologerProfile?.data?.data?.astrologer?.pause_account ? 0 : 1;
        Swal.fire({
            title: `Do you want to ${AstrologerProfile?.data?.data?.astrologer?.pause_account ? "Unpause" : "Pause"} the Astrologer`,
            confirmButtonColor: "#36AA00",
            confirmButtonText: AstrologerProfile?.data?.data?.astrologer?.pause_account ? "Unpause" : "Pause",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
        }).then((result) => {
            if (result?.isConfirmed)
                dispatch(UpdatePauseOrUnPauseAstrologer({ token, id, status }))
        })
    }

    const handleViewOrder = () => {
        let id = AstrologerProfile?.data?.data?.astrologer?.id;
        let slug = AstrologerProfile?.data?.data?.astrologer?.slug;

        navigate(`/astrologer/order-history/${slug}/${id}`)
    }
    useEffect(() => {
        if (success?.status === 200) {
            dispatch(GetSingleAstrologer({ token, id }));
            setAstrologerEdit(false);
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "OK",
                timer: 5000,
            }).then((result) => {
                dispatch(successMessage(''));
            })
        }

        else if (errors?.status === 400) {
            dispatch(GetSingleAstrologer({ token, id }));
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then((result) => {
                dispatch(errorMessage({ errors: '' }));

            })
        }
    }, [dispatch, token, id, success, errors]);

    return (
        <>
            <MainPagetitle pageTitle="Astrologer Profile Details" parentTitle="Home" />
            {
                astrologerEdit ?
                    <EditAstrologerProfile
                        show={astrologerEdit}
                        closeEdit={closeEdit}
                        astrologer={AstrologerProfile}
                    />
                    : null
            }
            <Container fluid>
                <Row className='d-flex flex-column align-items-center gap-3 astrologer-profile'>
                    <Col lg={11} className='d-flex justify-content-end gap-3 astrologer-profile-btn'>
                        <button
                            className='edit-btn'
                            onClick={() => setAstrologerEdit(true)}
                        >
                            Edit
                        </button>
                        <button
                            className='pause-btn'
                            onClick={() => handlePauseUnpauseAstrologer(AstrologerProfile?.data?.data?.astrologer?.id)}
                        >
                            {AstrologerProfile?.data?.data?.astrologer?.pause_account ? "Unpause" : "Pause"}
                        </button>
                        <button className='remove-btn'>Remove</button>
                    </Col>
                    <Col lg={11} className='astrologer-profile-card mb-3'>
                        <Col lg={12} className='d-flex p-3 astrologer-profile-card2'>
                            <Col lg={2} className='d-flex flex-column justify-content-center align-items-center'>
                                <div className='astrologer-profile-img'>
                                    <img src={profile ? profile?.file : EmptyProfile} alt="" />
                                </div>
                                <div className='mt-3'>
                                    <p>1050 Sessions</p>
                                </div>
                            </Col>
                            <Col lg={10} className='astro-personal-details'>
                                <Col lg={12} className='d-flex justify-content-between mb-2'>
                                    <div className='astrologer-online-status'>
                                        {
                                            AstrologerProfile?.data?.data?.astrologer?.call_active_status || AstrologerProfile?.data?.data?.astrologer?.chat_active_status ?
                                                <h5>Online : </h5>
                                                : null
                                        }
                                        {
                                            AstrologerProfile?.data?.data?.astrologer?.call_active_status ?
                                                <button>Call</button>
                                                : null
                                        }
                                        {
                                            AstrologerProfile?.data?.data?.astrologer?.chat_active_status ?
                                                <button>Chat</button>
                                                : null
                                        }
                                    </div>
                                    <div>
                                        <button onClick={() => { handleViewOrder() }}>View Order History</button>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <h3>Personal Details</h3>
                                </Col>
                                <Col lg={12} className='d-flex flex-wrap details'>
                                    <Col lg={4}>
                                        <p className='preview-data'>AMC Id : <span>{AstrologerProfile?.data?.data?.astrologer?.amc_id ? AstrologerProfile?.data?.data?.astrologer?.amc_id : "none"} </span></p>
                                    </Col>
                                    <Col lg={4}>
                                        <p className='preview-data'>Name : <span>{AstrologerProfile?.data?.data?.astrologer?.name ? AstrologerProfile?.data?.data?.astrologer?.name : "none"} </span></p>
                                    </Col>
                                    <Col lg={4}>
                                        <p className='preview-data'>DOB : <span>{AstrologerProfile?.data?.data?.astrologer?.dateofbirth ? AstrologerProfile?.data?.data?.astrologer?.dateofbirth : "none"} </span></p>
                                    </Col>
                                    <Col lg={4}>
                                        <p className='preview-data'>Gender : <span>{AstrologerProfile?.data?.data?.astrologer?.gender ? AstrologerProfile?.data?.data?.astrologer?.gender : "none"}</span></p>
                                    </Col>
                                    <Col lg={4}>
                                        <p className='preview-data'>Age : <span>{AstrologerProfile?.data?.data?.astrologer?.age ? AstrologerProfile?.data?.data?.astrologer?.age : "none"}</span></p>
                                    </Col>
                                    <Col lg={4}>
                                        <p className='preview-data'>Marital Status : <span>{AstrologerProfile?.data?.data?.astrologer?.maritalstatus ? AstrologerProfile?.data?.data?.astrologer?.maritalstatus : "none"}</span></p>
                                    </Col>
                                    <Col lg={4}>
                                        <p className='preview-data'>Consultation Rate : <span> {AstrologerProfile?.data?.data?.astrologer?.myrate ? `₹ ${AstrologerProfile?.data?.data?.astrologer?.myrate} / min` : "none"}</span></p>
                                    </Col>
                                    <Col lg={4}>
                                        <p className='preview-data'>Astrologer Share : <span> {AstrologerProfile?.data?.data?.astrologer?.myshare_percentage ? `${AstrologerProfile?.data?.data?.astrologer?.myshare_percentage} %` : "none"} </span></p>
                                    </Col>
                                </Col>
                            </Col>
                        </Col>
                        <Col lg={12} className='astrologer-rating-card p-3 '>
                            <Col lg={4} className='d-flex align-items-center'>
                                <h4>Ratings : <span>{AstrologerProfile?.data?.data?.astrologer?.rating}</span>
                                    <span className='mx-3'>
                                        {[...new Array(5)].map((arr, index) => {
                                            return index < `${AstrologerProfile?.data?.data?.astrologer?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                        })}
                                    </span>
                                </h4>
                            </Col>

                            <Col lg={6} className='astrologer-review-count'>
                                <div>
                                    <p className='count'>{AstrologerProfile?.data?.data?.astrologer?.totelCompletedCallOrders}</p>
                                    <p>Call</p>
                                </div>
                                <div>
                                    <p className='count'>{AstrologerProfile?.data?.data?.astrologer?.totelCompletedChatOrders}</p>
                                    <p>Chat</p>
                                </div>
                                <div>
                                    <p className='count'>50</p>
                                    <p>Reviews</p>
                                </div>
                                <div>
                                    <p className='count'>230</p>
                                    <p> Followers</p>
                                </div>
                            </Col>
                        </Col>
                    </Col>
                    <Col lg={11} className='astrologer-professional-details p-4'>
                        <div>
                            <h3>Professional Details</h3>
                        </div>
                        <Col lg={12} className='d-flex flex-wrap mb-3'>

                            <Col lg={6}>
                                <p className='preview-data'>Profession : <span>{AstrologerProfile?.data?.data?.astrologer?.profession ? AstrologerProfile?.data?.data?.astrologer?.profession : "none"} </span></p>
                            </Col>
                            <Col lg={6}>
                                <p className='preview-data'>Experience in years : <span>{AstrologerProfile?.data?.data?.astrologer?.experience ? AstrologerProfile?.data?.data?.astrologer?.experience : "0"} years </span></p>
                            </Col>
                            <Col lg={6}>
                                <p className='preview-data'>Qualification : <span>{AstrologerProfile?.data?.data?.astrologer?.qualification ? AstrologerProfile?.data?.data?.astrologer?.qualification : "none"}</span></p>
                            </Col>
                            <Col lg={6}>
                                <p className='preview-data'>Languages : <span>{language ? language?.join(", ") : "none"}</span></p>
                            </Col>
                            <Col lg={12}>
                                <p className='preview-data'>Areas of Expertise : <span>{description ? description?.join(", ") : "none"}</span></p>
                            </Col>
                            <Col lg={12}>
                                <p className='preview-data'>About : <span>{AstrologerProfile?.data?.data?.astrologer?.about ? AstrologerProfile?.data?.data?.astrologer?.about : "none"}</span></p>
                            </Col>
                        </Col>

                        <div>
                            <h3>Bank Details</h3>
                        </div>
                        <Col lg={12} className='d-flex flex-wrap mb-3'>
                            <Col lg={4}>
                                <p className='preview-data'>Holder name : <span>{AstrologerProfile?.data?.data?.bank?.accountholder_name ? AstrologerProfile?.data?.data?.bank?.accountholder_name : "none"}</span></p>
                            </Col>
                            <Col lg={4}>
                                <p className='preview-data'>Account number : <span>{AstrologerProfile?.data?.data?.bank?.accountnumber ? AstrologerProfile?.data?.data?.bank?.accountnumber : "none"}</span></p>
                            </Col>
                            <Col lg={4}>
                                <p className='preview-data'>Bank : <span>{AstrologerProfile?.data?.data?.bank?.bank_name ? AstrologerProfile?.data?.data?.bank?.bank_name : "none"}</span></p>
                            </Col>
                            <Col lg={4}>
                                <p className='preview-data'>Branch : <span>{AstrologerProfile?.data?.data?.bank?.branch ? AstrologerProfile?.data?.data?.bank?.branch : "none"}</span></p>
                            </Col>
                            <Col lg={4}>
                                <p className='preview-data'>IFSC Code : <span>{AstrologerProfile?.data?.data?.bank?.IFSCcode ? AstrologerProfile?.data?.data?.bank?.IFSCcode : "none"}</span></p>
                            </Col>
                            <Col lg={4}>
                                <p className='preview-data'>Pan number : <span>{AstrologerProfile?.data?.data?.bank?.pancard ? AstrologerProfile?.data?.data?.bank?.pancard : "none"}</span></p>
                            </Col>
                        </Col>
                        <Col lg={12} className='d-flex flex-wrap'>
                            <Col lg={6}>
                                <div>
                                    <h3>Proof</h3>
                                </div>
                                <div className="d-flex gap-4 mb-4">
                                    <p className='preview-data'>ID proof :</p>
                                    <div >
                                        {idProof ?
                                            <Button type={"primary"} className="preview-download-btn" href={idProof?.file}>
                                                Download
                                            </Button>
                                            : <p>file not found</p>
                                        }

                                    </div>
                                </div>
                                <div className="d-flex gap-4 mb-4">
                                    <p className='preview-data'>Bank passbook :</p>
                                    <div>
                                        {passbook ?
                                            <Button type={"primary"} className="preview-download-btn" href={passbook?.file}>
                                                Download
                                            </Button>
                                            : <p>file not found</p>
                                        }

                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div>
                                    <h3>Contact Details</h3>
                                </div>
                                <div>
                                    <p className='preview-data'>Phone Number : <span>{AstrologerProfile?.data?.data?.astrologer?.phone ? AstrologerProfile?.data?.data?.astrologer?.phone : "none"}</span></p>
                                </div>
                                <div>
                                    <p className='preview-data'>Email Id : <span>{AstrologerProfile?.data?.data?.astrologer?.email ? AstrologerProfile?.data?.data?.astrologer?.email : "none"}</span></p>
                                </div>
                            </Col>

                        </Col>
                        <div>
                            <h3>Seo Details</h3>
                        </div>
                        <Col lg={12} className='d-flex flex-column mb-3'>

                                <p className='preview-data'>Title : <span>{AstrologerProfile?.data?.data?.astrologer?.seo_title ? AstrologerProfile?.data?.data?.astrologer?.seo_title : "none"} </span></p>
                           
                                <p className='preview-data'>Description : <span>{AstrologerProfile?.data?.data?.astrologer?.seo_description ? AstrologerProfile?.data?.data?.astrologer?.seo_description : "none"} </span></p>

                                <p className='preview-data'>Keywords : <span>{AstrologerProfile?.data?.data?.astrologer?.seo_keywords ? AstrologerProfile?.data?.data?.astrologer?.seo_keywords : "none"} </span></p>

                        </Col>
                    </Col>
                </Row>
            </Container >
        </>
    )
}

export default AstrologerProfile;