import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios';

const initialState = {
    Loading: '',
    success: '',
    errors: '',
    EmployeeRole: null,
    Employees: null,
    AdminProfile: '',
    Astrologers: null,
    AstrologerProfile: null,
    AstrologerOrderHistory: null,
    ChatHistory: null,
    Users: null,
    UnregisterUsers: null,
    OrderHistory: null,
    EarningsHistory: null,
    NewAstrologers: null,
    AstrologersEditRequest: null,
    AstrologerEditRequest: null,
    AstrologersChangesAccept: null,
    AstrologersChangesRejected: null,
    UnApprovedReviews: null,
    Wallet: null,
    WalletHistory: null,
    PaymentHistory: null,
    Offers: null,
    Offer: null,
    OfferAstrologerList: null,
    Blogs: null,
    Blog: null,
    DashboardData: null,
    Tickets: null,
    TicketMessages: null,
    Notifications: null,
    SendNotifications: null,
    ReportUsersList: null,
    AstrologersPayoutReportData: null,
    AmcIncomeReportData: null,
    AmcSalesReturnReportData: null,
    ReportDownloads: null,
};

export const AddEmployees = createAsyncThunk(
    'page/AddEmployees',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/admin/addadmin`, val?.employeeData,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                const { data } = response;
                return data;
            }
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const GetEmployeeRole = createAsyncThunk(
    'page/GetEmployeeRole',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-roles`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const CheckLoginStatus = createAsyncThunk(
    'page/CheckLoginStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/check-session`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                const { data } = response;
                return data;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const AdminLogin = createAsyncThunk(
    'page/Login',
    async (val, { rejectWithValue }) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/admin/login`, val);
            if (response.status === 200 || response.status === 201) {
                const { data } = response;
                return data;
            }
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const AdminChangePassword = createAsyncThunk(
    'page/AdminChangePassword',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/admin/change-password`, val?.passwordData,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                const { data } = response;
                return data;
            }
        } catch (error) {
            return rejectWithValue(error.response);
        }
    },
);

export const checkAutoLogin = createAsyncThunk(
    'page/checkAutoLogin',
    async (val, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('admin-auth-token');
            if (!token) {
                return val('/login');;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const LogOut = createAsyncThunk(
    'page/LogOut',
    async (val, { rejectWithValue }) => {
        try {
            const token = localStorage.removeItem('admin-auth-token');
            if (!token) {
                window.location.reload(false);
                return val('/login');
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAdminProfile = createAsyncThunk(
    'page/AstrologerProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/getadmin`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateAdminProfile = createAsyncThunk(
    'page/UpdateAdminProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin//editadmin`, val.adminData,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateAdminProfileImg = createAsyncThunk(
    'page/UpdateAdminProfileImg',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            if (val?.ExistFile === null) {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/upload-file`, val.formData,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/update-file/${val?.fileId}`, val.formData,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const DeleteAdminProfileImg = createAsyncThunk(
    'page/DeleteUserProfileImg',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/delete-file/${val?.fileId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllEmployee = createAsyncThunk(
    'page/GetAllEmployee',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.search) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/search-employees/${val?.page}?name=${val?.search}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.filter) {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/admin/filter-employees/${val?.page}`, val?.filter,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-all-admins/${val?.page}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllAstrologers = createAsyncThunk(
    'page/GetAllAstrologers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.search) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/search-astrologers/${val?.page}?name=${val?.search}&type=${val?.type}&pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.sort) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/sort-astrologers/${val?.page}?sort=${val?.sort}&sortby=${val?.sortby}&type=${val?.type}&pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.filter) {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/admin/filter-astrologers?type=${val?.type}&pagelimit=${val?.pageLimit}`, val?.filter,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == "all-astrologers") {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/getall-astrologer/${val?.page}?pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == "paused-astrologers") {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-paused-astrologers/${val?.page}?pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == "online-astrologers") {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-online-astrologers/${val?.page}?pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdatePauseOrUnPauseAstrologer = createAsyncThunk(
    'page/UpdatePauseOrUnPauseAstrologer',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/pause-unpause-astrologer-account/${val?.id}?status=${val?.status}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetSingleAstrologer = createAsyncThunk(
    'page/GetSingleAstrologer',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-astrologer/${val?.id}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateAstrologerProfile = createAsyncThunk(
    'page/UpdateAstrologerProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/edit-astrologer-profile`, val?.UpdateDetails,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetAstrologerOrderHistory = createAsyncThunk(
    'page/GetAstrologerOrderHistory',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-astrologer-order-history/${val?.page}?type=${val?.type}&astrologerId=${val?.id}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetChatSession = createAsyncThunk(
    'page/GetChatSession',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-session-messages/${val?.messageSessionId}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetAllUsers = createAsyncThunk(
    'page/GetAllUsers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.search) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/search-users/${val?.page}?name=${val?.search}&pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.filter) {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/admin/filter-users/${val?.page}?pagelimit=${val?.pageLimit}`, val?.filter,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/getall-users/${val?.page}?pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllUsersList = createAsyncThunk(
    'page/GetAllUsersList',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/getall-user-list`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllUnregisterUsers = createAsyncThunk(
    'page/GetAllUnregisterUsers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-unreg-users/${val?.page}?pagelimit=${val?.pageLimit}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const DeleteUnregisterUser = createAsyncThunk(
    'page/DeleteUnregisterUser',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/admin/delete-unreg-user/${val?.userId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);


export const ActivateOrRejectAstrologers = createAsyncThunk(
    'page/ActivateOrRejectAstrologers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/activate-or-reject-astrologer?status=${val?.status}`, val?.data,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetOrderHistory = createAsyncThunk(
    'page/GetOrderHistory',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.start_date || val?.end_date) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-call-or-chat-filter-orders/${val?.page}?pagelimit=${val?.pageLimit}&type=${val?.type}&startdate=${val?.start_date}&enddate=${val?.end_date}&status=${val?.status}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type === "call" && val?.search) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/search-call-orders/${val?.page}?pagelimit=${val?.pageLimit}&name=${val?.search}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type === "chat" && val?.search) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/search-chat-orders/${val?.page}?pagelimit=${val?.pageLimit}&name=${val?.search}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type === "call" && val?.status) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-call-orders/${val?.page}?pagelimit=${val?.pageLimit}&status=${val?.status}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type === "chat" && val?.status) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-chat-orders/${val?.page}?pagelimit=${val?.pageLimit}&status=${val?.status}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type === "call") {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-call-orders/${val?.page}?pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type === "chat") {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-chat-orders/${val?.page}?pagelimit=${val?.pageLimit}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetEarningsHistory = createAsyncThunk(
    'page/GetEarningsHistory',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-all-earnings/${val?.page}?pagelimit=${val?.pageLimit}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetNewAstrologers = createAsyncThunk(
    'page/GetNewAstrologers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/new-astrologers/${val?.page}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologersEditRequest = createAsyncThunk(
    'page/GetAstrologersEditRequest',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/astrologer-change-requests/${val?.page}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologerEditRequest = createAsyncThunk(
    'page/GetAstrologerEditRequest',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-single-change-request/${val?.id}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologersAcceptedEditRequest = createAsyncThunk(
    'page/GetAstrologersAcceptedEditRequest',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/astrologer-accepted-change-requests/${val?.page}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologersRejectedEditRequest = createAsyncThunk(
    'page/GetAstrologersRejectedEditRequest',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/astrologer-rejected-change-requests/${val?.page}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateAstrologersEditRequest = createAsyncThunk(
    'page/UpdateAstrologersEditRequest',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/edit-astrologer-change-request/${val?.id}`, val?.editData,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const AcceptOrRejectAstrologersRequest = createAsyncThunk(
    'page/AcceptAstrologersRequest',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/astrologer-accept-or-reject-change-request/${val?.id}?acceptstatus=${val?.status}`, val?.data,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetUnApprovedReviews = createAsyncThunk(
    'page/GetUnApprovedReviews',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-unapproved-reviews/${val?.page}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const ApproveOrRejectReviews = createAsyncThunk(
    'page/ApproveOrRejectReviews',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/approve-or-reject-review/${val?.reviewId}?status=${val?.status}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetWalletAstrologersOrUsers = createAsyncThunk(
    'page/GetWalletAstrologersOrUsers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.search) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/search-wallet/${val?.page}?pagelimit=${val?.pageLimit}&role=${val?.role}&name=${val?.search}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-wallet/${val?.page}?pagelimit=${val?.pageLimit}&role=${val?.role}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetWalletHistory = createAsyncThunk(
    'page/GetWalletHistory',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-a-single-wallet/${val?.page}?role=${val?.role}&id=${val?.id}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetPaymentHistory = createAsyncThunk(
    'page/GetPaymentHistory',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-a-user-tranz/${val?.page}?userId=${val?.userId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const ChangeWalletpin = createAsyncThunk(
    'page/ChangeWalletpin',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/change-pin`, val?.pinValues,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const CheckWalletPin = createAsyncThunk(
    'page/CheckWalletPin',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/validate-pin?pin=${val?.Pin}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateAstrologerOrUserWallet = createAsyncThunk(
    'page/UpdateAstrologerOrUserWallet',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/edit-wallet?role=${val?.role}`, val?.updatedAmount,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetUserWalletReport = createAsyncThunk(
    'page/GetUserWalletReport',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-amc-user-wallets`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const AddUsersPayment = createAsyncThunk(
    'page/AddUsersPayment',
    async (val, { rejectWithValue }) => {
        console.log(val?.token);
        
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/manual-recharge?success=1&rzrId=${val?.usersPayment?.razorpayId}&bonus=${val?.usersPayment?.bonus}&state=${val?.usersPayment?.state}&country=${val?.usersPayment?.country}&address=${val?.usersPayment?.address}&userAMCId=${val?.usersPayment?.amcId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);


export const CreateNewOffer = createAsyncThunk(
    'page/CreateNewOffer',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/create-offer`, val?.newOffer,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologerList = createAsyncThunk(
    'page/GetAstrologerList',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/getall-astrologer-list`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllOffers = createAsyncThunk(
    'page/GetAllOffers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/getall-offers`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);
export const GetOffer = createAsyncThunk(
    'page/GetOffer',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-offer/${val?.offerId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateOffer = createAsyncThunk(
    'page/UpdateOffer',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/edit-offer`, val?.updateOffer,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const DeleteOffer = createAsyncThunk(
    'page/DeleteOffer',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/admin/delete-offer/${val?.offerId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const CreateNewBlogs = createAsyncThunk(
    'page/CreateNewBlogs',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/create-blog`, val?.newBlog,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllBlogs = createAsyncThunk(
    'page/GetAllBlogs',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.search || val?.date || val?.filterValue) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/search-blog/${val?.page}?type=${val?.type}&name=${val?.search}&date=${val?.date}&status=${val?.filterValue}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == 'all') {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-all-blogs/${val?.page}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == 'approval') {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-unapproved-blogs/${val?.page}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == 'delete') {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-deleted-blogs/${val?.page}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetBlog = createAsyncThunk(
    'page/GetBlog',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-blog/${val?.blogId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateBlogs = createAsyncThunk(
    'page/UpdateBlogs',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/edit-blog`, val?.updateBlog,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const DeleteBlog = createAsyncThunk(
    'page/DeleteBlog',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/admin/delete-blog/${val?.blogId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const RestoreOrDeleteBlog = createAsyncThunk(
    'page/RestoreOrDeleteBlog',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/admin/restore-or-delete-blog/${val?.blogId}?status=${val?.status}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetDashboardData = createAsyncThunk(
    'page/GetDashboardData',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-dashboard-data`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllTickets = createAsyncThunk(
    'page/GetAllTickets',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-all-support-tickets?role=${val?.role}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateTicketStatus = createAsyncThunk(
    'page/UpdateTicketStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/update-ticket-status/${val?.ticketId}?status=${val?.ticketStatus}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetTicketMessages = createAsyncThunk(
    'page/GetTicketMessages',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-support-messages/${val?.ticketId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const SendNotification = createAsyncThunk(
    'page/SendNotification',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/create-notification`, val?.notificationContent,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllNotifications = createAsyncThunk(
    'page/GetAllNotifications',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-notifications`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetSendedNotifications = createAsyncThunk(
    'page/GetSendedNotifications',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-created-notifications`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateNotificationSeenStatus = createAsyncThunk(
    'page/UpdateNotificationSeenStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/set-seen-notification/${val?.id}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologersPayoutReport = createAsyncThunk(
    'page/GetAstrologersPayoutReport',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-astrologer-payout`, val?.payoutReportDetails,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAmcIncomeReport = createAsyncThunk(
    'page/GetAmcIncomeReport',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-amc-earnings`, val?.incomeReportDetails,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAmcSalesReturnReport = createAsyncThunk(
    'page/GetAmcSalesReturnReport',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-amc-salesreturns`, val?.salesReturnReportDetails,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllDownloadReports = createAsyncThunk(
    'page/GetAllDownloadReports',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.isPayoutReport) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/getall-astrologer-payout-report`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.isIncomeReport) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/getall-amc-income-report`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.isSalesReturnReport) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/getall-user-salesreturn-report`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.isUserWalletReport) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/getall-user-wallet-report`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const ReduxSlice = createSlice({
    name: 'astromind',
    initialState: initialState,
    reducers: {
        errorMessage: (state, action) => {
            state.errors = action.payload.errors;
        },
        successMessage: (state, action) => {
            state.success = action.payload.successess;
        },
        loadingStatus: (state, action) => {
            state.Loading = action.payload.loadingStatus;
        },
    },
    extraReducers: (builder) => {
        builder
            //Add Employee
            .addCase(AddEmployees.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AddEmployees.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(AddEmployees.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Employee Role
            .addCase(GetEmployeeRole.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetEmployeeRole.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.EmployeeRole = action.payload;
            })
            .addCase(GetEmployeeRole.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.EmployeeRole = null;
            })

            //CheckLoginStatus
            .addCase(CheckLoginStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(CheckLoginStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(CheckLoginStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Login
            .addCase(AdminLogin.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AdminLogin.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(AdminLogin.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })


            //Get Admin Profile
            .addCase(GetAdminProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAdminProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AdminProfile = action.payload;
            })
            .addCase(GetAdminProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AdminProfile = {};
            })

            //Update Admin Profile
            .addCase(UpdateAdminProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateAdminProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateAdminProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Change Admin Password
            .addCase(AdminChangePassword.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AdminChangePassword.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(AdminChangePassword.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get All Employees
            .addCase(GetAllEmployee.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllEmployee.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Employees = action.payload;
            })
            .addCase(GetAllEmployee.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Employees = null;
            })

            //Get All Astrologers
            .addCase(GetAllAstrologers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllAstrologers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Astrologers = action.payload;
            })
            .addCase(GetAllAstrologers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Astrologers = null;
            })

            //Update Pause Or Unpause Astrologers
            .addCase(UpdatePauseOrUnPauseAstrologer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdatePauseOrUnPauseAstrologer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdatePauseOrUnPauseAstrologer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Single Astrologer Profile
            .addCase(GetSingleAstrologer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetSingleAstrologer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologerProfile = action.payload;
            })
            .addCase(GetSingleAstrologer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologerProfile = null;
            })

            //Update Astrologer Profile
            .addCase(UpdateAstrologerProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateAstrologerProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateAstrologerProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Astrologer Order History
            .addCase(GetAstrologerOrderHistory.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologerOrderHistory.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologerOrderHistory = action.payload;
            })
            .addCase(GetAstrologerOrderHistory.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologerOrderHistory = null;
            })

            //chat history
            .addCase(GetChatSession.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetChatSession.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ChatHistory = action.payload;
            })
            .addCase(GetChatSession.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ChatHistory = null;
            })

            //Get All Users
            .addCase(GetAllUsers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllUsers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Users = action.payload;
            })
            .addCase(GetAllUsers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Users = null;
            })

            //Get All Users List
            .addCase(GetAllUsersList.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllUsersList.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ReportUsersList = action.payload;
            })
            .addCase(GetAllUsersList.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ReportUsersList = null;
            })

            //Get UnRegister Users
            .addCase(GetAllUnregisterUsers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllUnregisterUsers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.UnregisterUsers = action.payload;
            })
            .addCase(GetAllUnregisterUsers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.UnregisterUsers = null;
            })

            //Delete UnRegister User
            .addCase(DeleteUnregisterUser.pending, (state) => {
                state.Loading = true;
            })
            .addCase(DeleteUnregisterUser.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(DeleteUnregisterUser.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })


            //Get Astrologer Order History
            .addCase(GetOrderHistory.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetOrderHistory.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.OrderHistory = action.payload;
            })
            .addCase(GetOrderHistory.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.OrderHistory = null;
            })

            //Get Earnings History
            .addCase(GetEarningsHistory.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetEarningsHistory.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.EarningsHistory = action.payload;
            })
            .addCase(GetEarningsHistory.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.EarningsHistory = null;
            })

            //Get New Astrologers
            .addCase(GetNewAstrologers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetNewAstrologers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.NewAstrologers = action.payload;
            })
            .addCase(GetNewAstrologers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.NewAstrologers = null;
            })

            //Activate or Reject Astrologer
            .addCase(ActivateOrRejectAstrologers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(ActivateOrRejectAstrologers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(ActivateOrRejectAstrologers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Astrologers Edit Request
            .addCase(GetAstrologersEditRequest.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologersEditRequest.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologersEditRequest = action.payload;
            })
            .addCase(GetAstrologersEditRequest.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologersEditRequest = null;
            })

            //Get Astrologer Edit Request
            .addCase(GetAstrologerEditRequest.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologerEditRequest.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologerEditRequest = action.payload;
            })
            .addCase(GetAstrologerEditRequest.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologerEditRequest = null;
            })

            //Get Astrologers Edit accept Request
            .addCase(GetAstrologersAcceptedEditRequest.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologersAcceptedEditRequest.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologersChangesAccept = action.payload;
            })
            .addCase(GetAstrologersAcceptedEditRequest.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologersChangesAccept = null;
            })

            //Get Astrologers Edit accept Request
            .addCase(GetAstrologersRejectedEditRequest.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologersRejectedEditRequest.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologersChangesRejected = action.payload;
            })
            .addCase(GetAstrologersRejectedEditRequest.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologersChangesRejected = null;
            })

            //Edit Astrologers Edit Request
            .addCase(UpdateAstrologersEditRequest.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateAstrologersEditRequest.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateAstrologersEditRequest.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = ' ';
            })

            //Accept or Reject Astrologers Edit Request
            .addCase(AcceptOrRejectAstrologersRequest.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AcceptOrRejectAstrologersRequest.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(AcceptOrRejectAstrologersRequest.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Unapproved Reviews
            .addCase(GetUnApprovedReviews.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetUnApprovedReviews.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.UnApprovedReviews = action.payload;
            })
            .addCase(GetUnApprovedReviews.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.UnApprovedReviews = null;
            })

            //Accept Or Reject Reviews
            .addCase(ApproveOrRejectReviews.pending, (state) => {
                state.Loading = true;
            })
            .addCase(ApproveOrRejectReviews.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(ApproveOrRejectReviews.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Users Or Astrologers Wallet
            .addCase(GetWalletAstrologersOrUsers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetWalletAstrologersOrUsers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Wallet = action.payload;
            })
            .addCase(GetWalletAstrologersOrUsers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Wallet = null;
            })

            //Get Users Or Astrologers Wallet History
            .addCase(GetWalletHistory.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetWalletHistory.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.WalletHistory = action.payload;
            })
            .addCase(GetWalletHistory.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.WalletHistory = null;
            })

            //Get Users Payment History
            .addCase(GetPaymentHistory.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetPaymentHistory.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.PaymentHistory = action.payload;
            })
            .addCase(GetPaymentHistory.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.PaymentHistory = null;
            })

            //Change the Wallet Pin
            .addCase(ChangeWalletpin.pending, (state) => {
                state.Loading = true;
            })
            .addCase(ChangeWalletpin.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(ChangeWalletpin.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Check Wallet Pin is Valid
            .addCase(CheckWalletPin.pending, (state) => {
                state.Loading = true;
            })
            .addCase(CheckWalletPin.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(CheckWalletPin.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Users Or Astrologers Wallet History
            .addCase(UpdateAstrologerOrUserWallet.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateAstrologerOrUserWallet.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateAstrologerOrUserWallet.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Users Wallet Report
            .addCase(GetUserWalletReport.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetUserWalletReport.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(GetUserWalletReport.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Add Users Payment Details
            .addCase(AddUsersPayment.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AddUsersPayment.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(AddUsersPayment.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Update and Upload Admin Profile Image 
            .addCase(UpdateAdminProfileImg.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateAdminProfileImg.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateAdminProfileImg.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Delete Astrologer Profile Image
            .addCase(DeleteAdminProfileImg.pending, (state) => {
                state.Loading = true;
            })
            .addCase(DeleteAdminProfileImg.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(DeleteAdminProfileImg.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Create New Offer
            .addCase(CreateNewOffer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(CreateNewOffer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(CreateNewOffer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get All Astrologer List
            .addCase(GetAstrologerList.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologerList.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.OfferAstrologerList = action.payload;
            })
            .addCase(GetAstrologerList.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.OfferAstrologerList = '';
            })

            //Get All Offers
            .addCase(GetAllOffers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllOffers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Offers = action.payload;
            })
            .addCase(GetAllOffers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Offers = null;
            })

            //Get Offer
            .addCase(GetOffer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetOffer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Offer = action.payload;
            })
            .addCase(GetOffer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Offer = null;
            })

            //Delete Offer
            .addCase(UpdateOffer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateOffer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateOffer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Delete Offer
            .addCase(DeleteOffer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(DeleteOffer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(DeleteOffer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Create New Blog
            .addCase(CreateNewBlogs.pending, (state) => {
                state.Loading = true;
            })
            .addCase(CreateNewBlogs.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(CreateNewBlogs.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get All Blogs
            .addCase(GetAllBlogs.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllBlogs.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Blogs = action.payload;
            })
            .addCase(GetAllBlogs.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Blogs = null;
            })

            //Get Blog
            .addCase(GetBlog.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetBlog.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Blog = action.payload;
            })
            .addCase(GetBlog.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Blog = null;
            })

            //Update Blog
            .addCase(UpdateBlogs.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateBlogs.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateBlogs.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Delete Blog
            .addCase(DeleteBlog.pending, (state) => {
                state.Loading = true;
            })
            .addCase(DeleteBlog.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(DeleteBlog.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Delete Or Restore Blog
            .addCase(RestoreOrDeleteBlog.pending, (state) => {
                state.Loading = true;
            })
            .addCase(RestoreOrDeleteBlog.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(RestoreOrDeleteBlog.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Dashboard Data
            .addCase(GetDashboardData.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetDashboardData.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.DashboardData = action.payload;
            })
            .addCase(GetDashboardData.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.DashboardData = null;
            })

            //Get All Tickets
            .addCase(GetAllTickets.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllTickets.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Tickets = action.payload;
            })
            .addCase(GetAllTickets.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Tickets = null;
            })

            //Update Ticket Status
            .addCase(UpdateTicketStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateTicketStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateTicketStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Tickets Messages
            .addCase(GetTicketMessages.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetTicketMessages.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.TicketMessages = action.payload;
            })
            .addCase(GetTicketMessages.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.TicketMessages = null;
            })

            //Send Notifications
            .addCase(SendNotification.pending, (state) => {
                state.Loading = true;
            })
            .addCase(SendNotification.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(SendNotification.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get All Notifications
            .addCase(GetAllNotifications.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllNotifications.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Notifications = action.payload;
            })
            .addCase(GetAllNotifications.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Notifications = null;
            })

            //Get Sended Notifications
            .addCase(GetSendedNotifications.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetSendedNotifications.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.SendNotifications = action.payload;
            })
            .addCase(GetSendedNotifications.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.SendNotifications = null;
            })

            //Update Notification Seen Status 
            .addCase(UpdateNotificationSeenStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateNotificationSeenStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateNotificationSeenStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Astrologers Payout Report
            .addCase(GetAstrologersPayoutReport.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologersPayoutReport.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologersPayoutReportData = action.payload;
            })
            .addCase(GetAstrologersPayoutReport.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologersPayoutReportData = null;
            })

            //Get AMC Income Report
            .addCase(GetAmcIncomeReport.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAmcIncomeReport.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AmcIncomeReportData = action.payload;
            })
            .addCase(GetAmcIncomeReport.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AmcIncomeReportData = null;
            })

            //Get AMC Sales Return Report
            .addCase(GetAmcSalesReturnReport.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAmcSalesReturnReport.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AmcSalesReturnReportData = action.payload;
            })
            .addCase(GetAmcSalesReturnReport.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AmcSalesReturnReportData = null;
            })

            //Get All Download Reports
            .addCase(GetAllDownloadReports.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllDownloadReports.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ReportDownloads = action.payload;
            })
            .addCase(GetAllDownloadReports.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ReportDownloads = null;
            })
    },

})


export const { errorMessage,
    successMessage,
    loadingStatus, loginStatus } = ReduxSlice.actions;
export default ReduxSlice.reducer;