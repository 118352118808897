import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useFormik } from 'formik';
import Swal from 'sweetalert2';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AddUsersPayment, successMessage, errorMessage } from '../../../Redux/Slice';
import { Navigate } from 'react-router-dom';

const AddPaymentDetails = ({ show, close }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { success, errors } = useSelector((state) => state.Astromind);

    const token = localStorage.getItem('admin-auth-token');

    const formik = useFormik({
        initialValues: {
            amcId: '',
            razorpayId: '',
            bonus: '',
            address: '',
            state: '',
            country: ''
        },
        onSubmit: async (values) => {
            let usersPayment = values;
            console.log(usersPayment);
            dispatch(AddUsersPayment({ token, usersPayment }));
        },
        validate: (values) => {
            const errors = {};
            const formatRegex = /^AMCU[A-Za-z0-9]{4}$/;

            if (!values.amcId) {
                errors.amcId = "Requried*";
            }
            else if (!formatRegex.test(values.amcId)) {
                errors.amcId = 'Invalid format. It should be in the format AMCUXXXX.';
            }
            if (!values.razorpayId) {
                errors.razorpayId = "Requried*";
            }
            if (!values.bonus) {
                errors.bonus = "Requried*";
            }
            if (!values.address) {
                errors.address = "Requried*";
            }
            if (!values.state) {
                errors.state = "Requried*";
            }
            if (!values.country) {
                errors.country = "Requried*";
            }
            return errors;
        }
    })

    useEffect(() => {
        // if (success?.status === 200) {
        //     Swal.fire({
        //         title: success?.data?.message,
        //         icon: "error",
        //         iconColor: "#CA0505",
        //         confirmButtonColor: "#CA0505",
        //         confirmButtonText: "Okay",
        //         timer: 5000
        //     }).then(() => {
        //         console.log(success?.data?.data?.userId);
                
        //         navigate(`/user-payment-transactions/${success?.data?.data?.userId}`);
        //         dispatch(successMessage({ success: '' }));
        //     })
        // }
       if (errors?.status === 400) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                if (result?.isConfirmed) {
                    dispatch(errorMessage({ errors: '' }));
                }
            })
        }
    }, [dispatch, success, errors]);

    console.log(success);


    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => close(false)}
                className='add-payment-modal'
            >
                <Modal.Header closeButton>
                    <h3>Add Payment</h3>
                </Modal.Header>
                <Modal.Body >
                    <div>
                        <div className='payment-input mb-4'>
                            <div className='d-flex justify-content-between'>
                                <h5>AMC Id <span className='text-red'>*</span></h5>
                                <p className={"error__feedback"}>{formik.errors.amcId}</p>
                            </div>
                            <input
                                type="text"
                                name='amcId'
                                id='amcId'
                                value={formik.values.amcId}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className='payment-input mb-4'>
                            <div className='d-flex justify-content-between'>
                                <h5>Razorpay Id <span className='text-red'>*</span></h5>
                                <p className={"error__feedback"}>{formik.errors.razorpayId}</p>
                            </div>
                            <input
                                type="text"
                                name='razorpayId'
                                id='razorpayId'
                                value={formik.values.razorpayId}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className='payment-input mb-4'>
                            <div className='d-flex justify-content-between'>
                                <h5>Bonus <span className='text-red'>*</span></h5>
                                <p className={"error__feedback"}>{formik.errors.bonus}</p>
                            </div>
                            <input
                                type="text"
                                name='bonus'
                                id='bonus'
                                value={formik.values.bonus}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className='payment-input mb-4'>
                            <div className='d-flex justify-content-between'>
                                <h5>Address <span className='text-red'>*</span></h5>
                                <p className={"error__feedback"}>{formik.errors.address}</p>
                            </div>
                            <input
                                type="text"
                                name='address'
                                id='address'
                                value={formik.values.address}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className='payment-input mb-4'>
                            <div className='d-flex justify-content-between'>
                                <h5>State <span className='text-red'>*</span></h5>
                                <p className={"error__feedback"}>{formik.errors.state}</p>
                            </div>
                            <input
                                type="text"
                                name='state'
                                id='state'
                                value={formik.values.state}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className='payment-input'>
                            <div className='d-flex justify-content-between'>
                                <h5>Country <span className='text-red'>*</span></h5>
                                <p className={"error__feedback"}>{formik.errors.country}</p>
                            </div>
                            <input
                                type="text"
                                name='country'
                                id='country'
                                value={formik.values.country}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default AddPaymentDetails;